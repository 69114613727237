<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800" persistent>
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>{{ $store.state.itemDetails.dialogType == "add" ? "إضافة" : "تعديل" }} {{ this.$route.meta.single }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    
                                    <!-- name -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            إسم الصلاحية
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.name"
                                            color="info"
                                            placeholder="مدير الطلبات"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- description -->
                                    <v-col cols="12" md="6">
                                        <h3 class="mb-2">
                                            وصف الصلاحية
                                            <span class="required">*</span>
                                        </h3>
                                        <v-text-field
                                            v-model="item.description"
                                            placeholder="متابة الطلبات , اضافة, تعديل, حذف"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            color="info"
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- roles -->
                                    <v-col cols="12" md="12">
                                        <h3 class="mb-2">
                                            أذونات
                                            <span class="required">*</span>
                                        </h3>
                                        <v-autocomplete
                                            chips
                                            multiple
                                            clearable
                                            color="info"
                                            v-model="selectedRoles"
                                            item-text="arCaption"
                                            item-value="id"
                                            :items="$global.state.roles"
                                            label="الصلاحيات"
                                            :loading="$global.state.loading"
                                            no-data-text="لا توجد بيانات"
                                            hide-details
                                            outlined
                                            filled
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                         <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :loading="$global.state.loading" x-large color="primary" class="btn secondary--text" :disabled="!valid">حفظ</v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            valid: false,
            selectedRoles: [],
            item: {},
        };
    },

    mounted() {
        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            this.selectedRoles = [];
            for (
                let i = 0;
                i < this.$store.state.itemDetails.roles.length;
                i++
            ) {
                this.selectedRoles[i] =
                    this.$store.state.itemDetails.roles[i].roleId;
            }
        });
    },

    created() {
        if (this.$global.state.roles == "") this.getRoles();
    },

    methods: {
        reset() {
            this.item = {
                name: "",
                description: "",
                roles: [],
            };
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        addItem() {
            this.$global.state.loading = true;
            for (let i = 0; i < this.selectedRoles.length; i++) {
                this.item.roles.push({
                    roleId: this.selectedRoles[i],
                });
            }
            this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        editItem() {
            this.$global.state.loading = true;
            this.item.roles = [];
            for (let i = 0; i < this.selectedRoles.length; i++) {
                this.item.roles.push({
                    roleId: this.selectedRoles[i],
                });
            }
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },
    
        getRoles() {
            this.$global.dispatch(`getRoles`);
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>
